@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;400;600&display=swap');
body {
    margin: 0;
    font-family:"Titillium Web","Geneva","Tahoma",sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .bold{
    font-weight: bold;
  }
  .App {
    text-align: center;
  }
  .center{
    margin: 0 auto;
    text-align:center;
  }
  .footer{
    position:relative;
    bottom:0;
    left:50%;
    margin-left:-200px;
    width:400px;
  }
  .footer p{
      text-align:center;
  }

  .navbar__link--active {
    border-bottom: 3px solid #29b6f6;
    transition: border-bottom .5s ease-in-out;
  }